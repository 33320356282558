import React, { Component } from "react";
export default class ContactUs extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="contact">
        <div className="row section-head">
          <div className="ten columns">
            <p className="lead">
              Feel free to contact me with any inquiries or suggestions below:
            </p>
          </div>
        </div>
        <div className="row">
          <aside className="eight columns footer-widgets">
            <div className="widget">
              <h4>
                Email me at {resumeData.email} or{" "}
                <a href="mailto:bradbartlett.dev@gmail.com">click here.</a>
              </h4>{" "}
              <br />
              <h4>Phone: {resumeData.phone}</h4>
            </div>
          </aside>
        </div>
      </section>
    );
  }
}
