import React, { Component } from "react";
export default class About extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="about">
        <div className="row">
          <div className="three columns">
            <img
              className="profile-pic"
              src="https://avatars3.githubusercontent.com/u/48605460?s=400&u=ef8e95234104997ec847c77668b56017ebd6f174&v=4"
              alt="Brad Bartlett"
            />
          </div>

          <div className="nine columns main-col">
            <p>{resumeData.aboutme}</p>

            <div className="row">
              <div className="columns contact-details">
                <h2>Contact Details</h2>
                <p className="address">
                  <br />
                  <span>
                    Email me at {resumeData.email} or{" "}
                    <a href="mailto:bradbartlett.dev@gmail.com">
                      click this link.
                    </a>
                  </span>
                  <br />
                  <span>Phone: {resumeData.phone}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
