let resumeData = {
  imagebaseurl: "https://techonoeticsclub.github.io/",
  name: "Brad Bartlett",
  role: "Full Stack Developer",
  linkedinId: "brad-bartlett",
  roleDescription:
    "Talented and motivated software developer experienced in effectively and efficiently working in dynamic environments. Proficient in JavaScript-based programming, Python, and Ruby on Rails with a background in entrepreneurship. Passionate about technology, solving problems with creative solutions, and constantly learning new advancements in this ever-changing field. Strong skills in team-building and project management to help companies drive agile software development. ",
  socialLinks: [
    {
      name: "linkedin",
      url: "https://www.linkedin.com/in/brad-bartlett/",
      className: "fa fa-linkedin",
    },
    {
      name: "github",
      url: "https://github.com/brad-bartlett",
      className: "fa fa-github",
    },
  ],
  email: "bradbartlett.dev@gmail.com",
  phone: "770 330 1632",
  website: "bradbartlett.dev",
  education: [
    {
      UniversityName: "Flatiron School",
      specialization: "Software Development & Computer Science",
    },
    {
      UniversityName: "The University of Alabama",
      specialization: "Marketing & Entrepreneurship",
    },
  ],
  work: [
    {
      CompanyName: "Andrews McMeel Universal",
      specialization: "Software Developer",
      // Achievements:
      //   "React/Typescript/Firebase stack using Redux and Material-UI. Personal contributions include auth flow with new screens for registering new users and logging in, multiple new admin management screens around game sessions and user permissions based on their role, inviting new users to the platform flow with integration of the ActiveCampaign and Mailgun APIs, new internal profile and user management pages.",
    },
    {
      CompanyName: "Present Company, LTD",
      specialization: "Software Developer",
      // Achievements:
      //   "React/Typescript/Firebase stack using Redux and Material-UI. Personal contributions include auth flow with new screens for registering new users and logging in, multiple new admin management screens around game sessions and user permissions based on their role, inviting new users to the platform flow with integration of the ActiveCampaign and Mailgun APIs, new internal profile and user management pages.",
    },
    {
      CompanyName: "Remote Social",
      specialization: "Software Developer",
      Achievements:
        "React/Typescript/Firebase stack using Redux and Material-UI. Personal contributions include auth flow with new screens for registering new users and logging in, multiple new admin management screens around game sessions and user permissions based on their role, inviting new users to the platform flow with integration of the ActiveCampaign and Mailgun APIs, new internal profile and user management pages.",
    },
    {
      CompanyName: "Akcelo",
      specialization: "Software Developer",
      Achievements:
        "Built and tested Heineken Australia’s new promo campaign site using React and Material-UI. This site is being used by tens of thousands of users.",
    },
    {
      CompanyName: "Cascade Health Services",
      specialization: "React/Javascript Developer",
      Achievements:
        "Mid-level React/Typescript Developer using XState and Material-UI. Collaborated with designers to build a platform for use by hospitals to fill empty shifts with registered nurses and other practitioners.",
    },
    {
      CompanyName: "Present Company, LTD",
      specialization: "Software Developer",
      Achievements:
        "Frond-end developer focused in React, Vanilla JS, API development, GraphQL, CSS and HTML. Built Western Sydney Univeristy's new e-learning platform which is currently being used by tens of thousands of students for taking assessments, campus orientation, and networking.",
    },
    {
      CompanyName: "Lasso Digital",
      specialization: "Software Developer",
      Achievements:
        "Built web designs and user experiences for websites, apps, and branding based on the client’s vision. Collected and translated client expectations into deployed products. Installed and configured software applications and tested solutions for effectiveness.",
    },
    {
      CompanyName: "Homegrown Capital",
      specialization: "Analyst",
      Achievements:
        "Evaluated potential projects and presented prospectus analysis. Negotiated contracts with subcontractors to manage budgets and timelines.",
    },
    {
      CompanyName: "Healthy Atlanta Vending",
      specialization: "Founder/Software Developer",
      Achievements:
        "Built a proprietary sales tracking application using Javascript and React to get real-time sales data. Achieved industry-leading profits by optimizing the supply chain process to meet dynamic conditions in the field. Won and negotiated contracts with publicly traded companies, international hotel chains, property management firms, and Universities to solidify market presence. Coached and mentored like-minded people to work together toward a common goal.",
    },
  ],
  skillsDescription: "",
  skills: [
    {
      skillname: "HTML5 & CSS",
      skillProficiency: "90%",
    },
    {
      skillname: "React.JS",
      skillProficiency: "92%",
    },
    {
      skillname: "Javascript",
      skillProficiency: "90%",
    },
    {
      skillname: "Typescript",
      skillProficiency: "85%",
    },
    {
      skillname: "Next.JS",
      skillProficiency: "95%",
    },
    {
      skillname: "Ruby & Ruby on Rails",
      skillProficiency: "90%",
    },
    {
      skillname: "Vue.JS",
      skillProficiency: "65%",
    },
    {
      skillname: "Node.JS",
      skillProficiency: "75%",
    },
    {
      skillname: "GraphQL",
      skillProficiency: "90%",
    },
    {
      skillname: "REST API",
      skillProficiency: "95%",
    },
    {
      skillname: "Database: PostGresql, MySQL, SQLite",
      skillProficiency: "85%",
    },
    {
      skillname: "Storybook",
      skillProficiency: "98%",
    },
  ],
  portfolio: [
    {
      name: "Chat App",
      description: "Created with React.JS, Express.JS, Node.JS, and Socket.IO",
      imgurl: "/images/portfolio/ezgif.com-gif-maker.gif",
      appurl: "//brad-bartlett-react-express-chat-app.netlify.com",
    },
    {
      name: "Trade Log App",
      description: "React.Js App",
      imgurl: "/images/portfolio/ezgif.com-gif-maker (1).gif",
      appurl: "//brad-bartlett-trade-log.netlify.com",
    },
  ],
  testimonials: [
    {
      description:
        "Brad’s background as a business owner has translated remarkably to this field. He brings that entrepreneurial lens to the quality of his work and his approach to problem solving. Brad is reliable and always takes initiative to go above and beyond expectations. His passion and curiosity is evident in how quickly he picks up new technologies in his spare time.",
      name: "Adrian Lawrence, Founder of Lasso Digital",
    },
  ],
};

export default resumeData;
