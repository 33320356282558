import React, { Component } from "react";
export default class Porfolio extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="portfolio">
        <div className="row">
          <div className="twelve columns collapsed">
            <h1>Portfolio</h1>
            <div
              id="portfolio-wrapper"
              className="bgrid-sixths s-bgrid-thirds cf"
            >
              {resumeData.portfolio &&
                resumeData.portfolio.map((item) => {
                  return (
                    <ul>
                      <div>
                        <div>
                          <img
                            src={`${item.imgurl}`}
                            className="item-img"
                            alt=""
                          />

                          <div className="portfolio-item-meta">
                            <h2>{item.name}</h2>
                            <h4>{item.description}</h4>
                            <a
                              href={item.appurl}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              Click here to try it out!
                            </a>
                          </div>
                        </div>
                      </div>
                    </ul>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
